<template>
<section class="section-narrow bg-gradient-cyrrus-orange-yellow text-black">
    <div class="section-content">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-10">
                    <h1>{{$t('index.heading')}}</h1>

                    <p>{{$t('index.subtitle')}}</p>

                    <div class="row">
                        <div class="col-12 col-md-8">
                            <loader-overlay
                                :loading="loading"
                            >
                                <div class="bg-white cyrrus-rounded p-5 shadow form-fx mt-4 mb-5">
                                    <h3 class="h2 text-cyrrus-yellow">{{$t('index.formTitle')}}</h3>
                                    <b-form @submit.prevent="onSubmit">
                                        <form-input
                                            :label="$t('index.usernameLabel')"
                                            v-model="username"
                                            :validation="$v.username"
                                            :formatter="(value) => `${value}`.replace(/\D/ig, '')"
                                            id="index.username"
                                        />

                                        <form-input
                                            type="password"
                                            :label="$t('index.passwordLabel')"
                                            v-model="password"
                                            :validation="$v.password"
                                            id="index.password"
                                        />

                                        <b-alert
                                            v-if="error"
                                            variant="danger"
                                            show
                                        >
                                            {{$t('index.loginError')}}
                                        </b-alert>

                                        <div class="mt-4">
                                            <b-btn
                                                variant="primary"
                                                type="submit"
                                                :disabled="loading"
                                            >
                                                <span class="iconify mr-1" data-icon="mdi:key"></span> {{$t('index.login')}}
                                            </b-btn>
                                        </div>
                                    </b-form>
                                </div>
                            </loader-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators'

export default {
    name: 'IndexPage',
    data () {
        return {
            username: null,
            password: null,
            error: false,
            loading: false
        }
    },
    methods: {
        async onSubmit () {
            this.$v.$reset()
            if (this.$v.$invalid) {
                return this.$v.$touch()
            }
            this.error = false
            this.loading = true
            this.$nextTick(async () => {
                try {
                    const response = await this.$api.auth.login(this.username, this.password)
                    if (response.status === 200) {
                        await this.$user.reload()
                        if (this.$user.isLoggedIn) {
                            this.$router.replace({ name: 'AccountSelect' }).catch(() => {})
                        }
                    } else {
                        this.error = true
                    }
                } catch (error) {
                    console.error(error)
                    this.error = true
                } finally {
                    this.$nextTick(() => {
                        this.loading = false
                    })
                }
            })
        }
    },
    validations: {
        username: {
            required,
            numeric
        },
        password: {
            required
        }
    }
}
</script>
